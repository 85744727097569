import React from 'react';

const NotFound = () => {
    return (
        <div className="container">
            <p></p>
            <p>Страница не найдена</p>
        </div>
    );
};

export default NotFound;
