import React from "react";

const PasswordReset = () => {
    return (
        <>
            <p>Password reset page</p>
        </>
    );
};

export default PasswordReset;