import React from 'react';
import KitchenClimate from "./KitchenClimate";
import Room5Climate from "./Room5Climate";
const AllRoomsClimate = () => {

    return (
        <div>
            <p></p>
            <KitchenClimate />
            <Room5Climate />
        </div>
    );
};

export default AllRoomsClimate;
