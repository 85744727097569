import React from 'react';


const Room5Climate = () => {

    return (
        <div className="container">
            <div className="card mt-2" style={{width: "20rem"}}>
                <div className="card-body">
                    <h4 className="card-title">Комната 5:</h4>
                    <p>Датчик изготавливается</p>
                </div>
            </div>
        </div>
    );
};

export default Room5Climate;
